import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codefresh/volume/bolt/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground } from 'docz';
import '../../dist/css/bolt-css.css';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "shadows"
    }}>{`Shadows`}</h1>
    <p>{`O style guide da Pismo dispõe de 5 níveis de box-shadow. Pode ser adicionado chamando o prefixo `}<inlineCode parentName="p">{`tw-shadows-`}</inlineCode>{` + deph, on o deph 0 é sem sombra e deph 5 é a maior.`}</p>
    <br />
    <Playground __position={1} __code={'() => {\n  const style = {\n    container: {\n      display: \'flex\',\n      flexDirection: \'column\',\n      alignItems: \'center\',\n      \'& p\': {\n        color: \'white\',\n      },\n    },\n    box: {\n      width: \'100px\',\n      height: \'100px\',\n      border: \'1px solid white\',\n      borderRadius: \'4px\',\n      backgroundColor: \'white\',\n      display: \'flex\',\n      justifyContent: \'center\',\n      alignItems: \'center\',\n      \'& h1\': {\n        fontFamily: \'Lato\',\n        fontWeight: \'bolder\',\n      },\n    },\n  }\n  return (\n    <div className=\"tw-flex tw-gap-5 tw-flex-wrap\">\n      <div style={style.container}>\n        <div style={style.box} className=\"tw-shadows-0\"></div>\n        <p>none</p>\n      </div>\n      <div style={style.container}>\n        <div style={style.box} className=\"tw-shadows-1\"></div>\n        <p>deph-1</p>\n      </div>\n      <div style={style.container}>\n        <div style={style.box} className=\"tw-shadows-2\"></div>\n        <p>deph-2</p>\n      </div>\n      <div style={style.container}>\n        <div style={style.box} className=\"tw-shadows-3\"></div>\n        <p>deph-3</p>\n      </div>\n      <div style={style.container}>\n        <div style={style.box} className=\"tw-shadows-4\"></div>\n        <p>deph-4</p>\n      </div>\n      <div style={style.container}>\n        <div style={style.box} className=\"tw-shadows-5\"></div>\n        <p>deph-5</p>\n      </div>\n    </div>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      Playground,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const style = {
          container: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            '& p': {
              color: 'white'
            }
          },
          box: {
            width: '100px',
            height: '100px',
            border: '1px solid white',
            borderRadius: '4px',
            backgroundColor: 'white',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            '& h1': {
              fontFamily: 'Lato',
              fontWeight: 'bolder'
            }
          }
        };
        return <div className="tw-flex tw-gap-5 tw-flex-wrap">
        <div style={style.container}>
          <div style={style.box} className="tw-shadows-0"></div>
          <p>none</p>
        </div>
        <div style={style.container}>
          <div style={style.box} className="tw-shadows-1"></div>
          <p>deph-1</p>
        </div>
        <div style={style.container}>
          <div style={style.box} className="tw-shadows-2"></div>
          <p>deph-2</p>
        </div>
        <div style={style.container}>
          <div style={style.box} className="tw-shadows-3"></div>
          <p>deph-3</p>
        </div>
        <div style={style.container}>
          <div style={style.box} className="tw-shadows-4"></div>
          <p>deph-4</p>
        </div>
        <div style={style.container}>
          <div style={style.box} className="tw-shadows-5"></div>
          <p>deph-5</p>
        </div>
      </div>;
      }}
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      